import React, {ChangeEvent, Fragment} from 'react';
import "./AddPost.scss";
import * as interfaces from "../../../redux/interfaces";
import {Pages} from "../../shared_components/functions/Navigation";


interface Props {
    postLeadershipPost: (leadershipId: string, postUrl: string, embedHtml: string, expiration: any,
                         notes: string, newTopic: string) => void;
    leadershipFetch: () => void;
    leaderships: interfaces.Leadership[];
    history?: any;
}

interface State {
    showDialog: boolean;
    showGuide: boolean;
    showForm: boolean;
    urlPost: any;
    embedPost: any;
    notes: any;
    newTopic: any;
    date: any;
    categoryId: any;
    showNewTopic: boolean;

}

export class AddPost extends React.PureComponent <Props, State> {


    constructor(props: Props) {
        super(props);

        this.state = {
            showForm: true,
            showDialog: false,
            showGuide: false,
            urlPost: '',
            embedPost: '',
            notes: '',
            newTopic: '',
            date: '',
            categoryId: -1,
            showNewTopic: false
        }
    }


    componentDidMount() {
        this.props.leadershipFetch();
    }

    closeButtonListener = () => {
        this.setState({showForm: true, showDialog: false, showGuide: false})
    }

    okayButtonListener = () => {
        this.closeButtonListener();
        this.navigateTo(Pages.leadership)
    }
    navigateTo = (pathname: string) => {
        this.props.history.push(pathname);
    }

    submitFromButtonListener = async () => {
        this.setState({showForm: false, showDialog: true, showGuide: false});
        let showNewTopic = this.state.showNewTopic;
        let newTopic = showNewTopic ? this.state.newTopic : '';
        let topicId = showNewTopic ? '' : this.props.leaderships[this.state.categoryId].id;
        let notes = this.state.notes;
        let date = this.state.date;
        let embedPost = this.state.embedPost;
        let urlPost = this.state.urlPost;
        await this.props.postLeadershipPost(
            topicId,
            urlPost,
            embedPost,
            date,
            notes,
            newTopic
        )
    }


    handleUrlPostInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({urlPost: event.target.value});
    }

    handleEmbedPostInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({embedPost: event.target.value});
    }


    handleUDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({date: event.target.value});
    }

    handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({notes: event.target.value});
    }

    handleCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "-1") {
            this.setState({showNewTopic: true});
        } else {
            this.setState({showNewTopic: false});
            this.setState({categoryId: event.target.value});
        }
    }

    private handleTopicNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({newTopic: event.target.value});
    }

    render() {
        return (
            <Fragment>
                <main
                    style={{height: window.innerHeight}}
                    className={`col bg-faded py-3 flex-grow-1 overflow-auto`}
                >
                    {(this.state.showForm || this.state.showGuide) && this.container()}
                    {this.state.showDialog && <SubmissionDialog okayButton={this.okayButtonListener}/>}
                </main>

            </Fragment>
        )
    }

    private container() {
        return <div className="form-container m-5">
            <div className="container">
                <div className="form-wrapper p-4">
                    {this.header()}
                    {this.state.showForm && this.form()}
                </div>
            </div>
        </div>;
    }

    private form() {

        return <form className="addpost-form col-10 pl-0 pt-4 tbody">
            {this.link()}
            {this.embedLink()}
            {this.category()}
            {this.newCategory()}
            {this.expirationDate()}
            {/*{this.note()}*/}
            {this.submitButton()}
        </form>
    }

    private submitButton() {


        const b = this.state.urlPost.length > 0 &&
            this.state.embedPost.length > 0 &&
            this.state.categoryId >= 0 &&
            this.state.date.length > 0

        return <button disabled={!b} type="button" className="btn btn-primary"
                       onClick={this.submitFromButtonListener}>Submit
            Post</button>
    }

    private note() {
        return <div className="form-group">
            <label>NOTE</label>
            <input type="text"
                   className="form-control form-control-lg" onChange={this.handleNoteChange}
                   value={this.state.notes}/>
        </div>
    }

    private expirationDate() {
        return <div className="form-group">
            <label>SET EXPIRATION</label>
            <input type="date"
                   className="form-control form-control-lg" onChange={this.handleUDateChange}
                   value={this.state.date}/>
        </div>
    }

    private category() {
        let leaderships = this.props.leaderships;
        return <div className="form-group">
            <label>CHOOSE TOPIC</label>
            <select multiple className="custom-select" onChange={this.handleCategoryChange}>
                {leaderships.map((leadership: interfaces.Leadership, index) => {
                    return (
                        <option className="py-1" key={index} value={index}>{leadership.title}</option>
                    )
                })}
                <option className="py-1" key={-1} value={-1}>ADD new Topic</option>
            </select>
        </div>
    }

    private embedLink() {
        return <div className="form-group">
            <label>{'<\\>'} EMBED THIS POST LINK</label>
            <input type="text"
                   className="form-control form-control-lg" onChange={this.handleEmbedPostInputChange}
                   value={this.state.embedPost}/>
        </div>
    }

    private link() {

        return <div className="form-group">
            <label>LINK TO POST</label>
            <input type="text"
                   className="form-control form-control-lg" onChange={this.handleUrlPostInputChange}
                   value={this.state.urlPost}/>
        </div>
    }

    // noinspection JSMethodCanBeStatic
    private header() {

        return <Fragment>
            <div className="row">
                <h2 className="col text-primary pt-2">New Submission From </h2>
            </div>
        </Fragment>
    }

    private newCategory() {
        return this.state.showNewTopic && <div className="form-group">
            <label>TOPIC NAME</label>
            <input type="text"
                   className="form-control form-control-lg" onChange={this.handleTopicNameInputChange}
                   value={this.state.newTopic}/>
        </div>
    }

}

const SubmissionDialog = (props: any) => {
    return (

        <div
            className="row d-flex align-items-center h-75">
            <div className="col-9 col-md-6 mx-auto">
                <div className="submission-success-wrapper py-5 text-primary text-center bg-white">
                    <h3>NEW SUBMISSION</h3>
                    <p>Sent for approval</p>
                    <button type="button" className="col-3 btn btn-primary mt-3 " onClick={props.okayButton}>OK</button>
                </div>
            </div>
        </div>
    )
}