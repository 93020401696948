import {connect} from "react-redux";
import {Leadership} from "./Leadership";
import {bindActionCreators} from "redux";
import {leadershipFetch } from "../../../redux/actions/Leadership.action";

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({ 
        leadershipFetch
    }, 
    dispatch);

const mapStateToProps = (state: any) => {
    return {
        leaderships:state.leadershipReducer.leaderships
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Leadership)
