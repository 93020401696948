import * as companyService from '../../services/company.service';
import { Leadership, Post ,Company} from '../interfaces';
import * as types from '../types';



export const companyFetch = ()=>{
    return async (dispatch:any)=> {
      try{
        const response = await companyService.getCompanyAPI('');

        let company:Company = {
            id:response.data._id,
            name:response.data.name,
            embedFacebook:response.data.embedFacebook,
            embedTwitter:response.data.embedTwitter
        }

        dispatch ({
            type: types.COMPANY_FETCH,
            company:company,
          });
      }catch(error:any){
        console.log(error)
      }
    }
  }