import React, {ChangeEvent, Fragment} from 'react';
import './Leadership.scss';
import {Column} from '../../shared_components/Column/Column';
import {Card} from '../../shared_components/Card/Card';
import * as interfaces from "../../../redux/interfaces";
import {getHeightFromStringIframe, getSrcFromStringIframe} from '../../shared_components/functions/htmlFunctions';
import {Pages} from '../../shared_components/functions/Navigation';
import {ChevronBarContract, Eye, PlusLg} from 'react-bootstrap-icons';
import {Post} from "../../../redux/interfaces";

interface Props {
    leadershipFetch: () => void;
    leaderships: interfaces.Leadership[];
    history?: any;
}


interface State {
    filter: string;
}

export class Leadership extends React.PureComponent <Props, State> {

    constructor(props: Props) {

        super(props);

        this.state = {
            filter: ''
        }
    }

    handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({filter: event.target.value});
    }

    onPostCLick = (event: Post) => {

        return () => {

            window.open(event.url)
        };
    }

    async componentDidMount() {
        await this.props.leadershipFetch();

    }

    navigateTo = (pathname: string) => {
        this.props.history.push(pathname);
    }

    render() {

        return (
            <Fragment>
                <main
                    className={`col bg-faded py-3 flex-grow-1`}
                >
                    <div className="container-fluid">
                        <div className="p-5 header1" style={{height: window.innerHeight}}>
                            {this.toolbar()}
                            {this.chooseType()}
                            {this.content()}
                        </div>
                    </div>
                </main>


            </Fragment>
        )
    }

    handleFilters = (event: ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value;
        if (value === "0") {
            this.setState({filter: ""});
        } else {
            const leadershipTitle = this.props.leaderships
                .filter((leadership: interfaces.Leadership) => !leadership.company)
                [parseInt(value) - 1]
                .title
            this.setState({filter: leadershipTitle})
        }
    }

    private content() {
        return <div className="elements-container d-flex flex-row-reverse pt-4 mt-4 overflow-x-scroll all-posts-container w-75">
            {this.props.leaderships
                .filter((leadership: interfaces.Leadership) => this.leadershipFilter(leadership))
                .map((leadership: interfaces.Leadership) => {
                    let posts = leadership.posts;
                    return (
                        <div className="element-container ml-auto col-12 mb-4 mb-md-0 col-lg-6 col-md-4 mt-1"
                             key={leadership.id}>
                            <Column title={leadership.title} height={`calc(${window.innerHeight}px - 200px)`}>
                                {posts
                                    .filter((post: interfaces.Post) => {
                                        return post.embedHtml && getSrcFromStringIframe(post.embedHtml);
                                    })
                                    .map((post: interfaces.Post) => {
                                        return this.getCard(post);
                                    })}
                            </Column>
                        </div>
                    )
                })}
        </div>;
    }

    private getCard(post: Post) {
        let srcFromStringIframe = getSrcFromStringIframe(post.embedHtml);
        let height = getHeightFromStringIframe(post.embedHtml) * 1.5
        return (
            <Card>
                <iframe

                    src={srcFromStringIframe} className="iframe"
                    height={`${height}px`}
                    scrolling="no"
                    width="100%"
                    frameBorder="0"
                    title="Embedded post"/>
            </Card>
        )
    }

    private leadershipFilter(leadership: interfaces.Leadership) {
        let passesFilter = this.state.filter === '' ||
            leadership.title.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0;
        return !leadership.company && (passesFilter)
    }

    private toolbar() {
        return <div className="row d-flex align-items-center">
            <h4 className="text-primary col-7 mb-0">Hi, Welcome to your Socialeyes engagement board</h4>
            <div className="buttons text-right col-5">
                <button type="button" className="btn btn-primary mb-2 mb-lg-0 mr-lg-2"
                        onClick={this.previewButtonOnClick()}><Eye className="preview-page-icon"/> Preview Page
                </button>
                <button type="button" className="btn btn-primary" onClick={this.addPostButtonOnClick()}><PlusLg
                    className="add-new-post-icon"/> Add New Post
                </button>
            </div>
        </div>;
    }

    private addPostButtonOnClick() {
        return () => this.navigateTo(Pages.addPost);
    }

    private previewButtonOnClick() {
        return () => {
            const companyId = localStorage.getItem("COMPANY_ID");
            window.open(`https://${process.env.REACT_APP_CLIENT_URL}/#/?companyId=` + companyId)
        };
    }

    private chooseType() {
        return <div className="form-group">
            <label>CHOOSE TOPIC </label>
            <select className="form-control" onChange={this.handleFilters}>
                <option value={0}>{"No Filter"}</option>
                {this.props.leaderships
                    .filter((leadership: interfaces.Leadership) => !leadership.company)
                    .map((leadership: interfaces.Leadership, index: number) => {
                        return (
                            <option value={index + 1}>{leadership.title}</option>
                        )
                    })}
            </select>
        </div>;
    }
}
