import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { appStore } from './redux/AppStore';
// import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter as Router, Route, HashRouter} from "react-router-dom";

import { Helmet } from 'react-helmet'


const theme = createMuiTheme({
  palette: {
    primary: {
      dark: '#1b5196',
      main:'#3f82c5',
      light:'#93b5d8'
    },
    secondary: {
      main: '#f9d72d',
      light:'fad830'
    }
  },
  typography: {
    fontFamily: ['Open Sans Hebrew', 'sans-serif'].join(',')
  },
  direction: 'rtl',
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 18,
        fontWeight: 'bold'
      }
    },
    MuiCheckbox: {
      root: {
        padding: '0'
      }
    }

}});

ReactDOM.render(
  <Provider store={appStore}>
  {/* <MuiThemeProvider theme={theme}> */}
  {/* </MuiThemeProvider> */}

  <>
<Helmet>
      <title>SocialEyes</title>
</Helmet>
  <Router>
      <HashRouter>
        <Route path="/" component={App} />
      </HashRouter>
  </Router>
</>,

</Provider>,document.getElementById('root')
);
