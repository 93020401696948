import { Leadership } from '../interfaces';
import * as types from '../types';

export interface LeaderShipState {
  leaderships:Leadership[]
  }
  
// INITIALIZE STATE
const initialState: LeaderShipState = {
  leaderships:[]
}


const leadershipReducer = (state: LeaderShipState = initialState, action: any) => {

    switch (action.type) {
      case types.LEADERSHIP_FETCH :
        return {...state,leaderships:action.leaderships};
      case types.NEW_POST:
        let leaderships :Leadership[] = [...state.leaderships];
        let index = state.leaderships.findIndex(leadership=>leadership.id===action.leadershipId);
        if(index===-1)
          return;
        leaderships[index].posts = [...leaderships[index].posts,action.post];
        return {...state,leaderships:[...leaderships]}
      default:
        return state;
    }
};

export default leadershipReducer;
