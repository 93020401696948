import {connect} from "react-redux";
import {Company} from "./Company";
import {bindActionCreators} from "redux";
import {companyFetch} from "../../../redux/actions/Company.action";
import {leadershipFetch} from "../../../redux/actions/Leadership.action";


const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({
            companyFetch, leadershipFetch
        },
        dispatch);

const mapStateToProps = (state: any) => {
    return {
        company: state.companyReducer.company, leaderships: state.leadershipReducer.leaderships

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Company)
