import React from 'react';
import "./Column.scss";

interface Props {
    children?:any;
    title:string;
    height?: number|string
    overflow?: string

}

interface State {

}
export class Column extends React.PureComponent <Props, State> {

    render(){
        return (
            <div className="col column-container mt-4"
            style={{height: this.props.height? this.props.height : '-webkit-fill-available',
                    overflow: this.props.overflow? this.props.overflow: 'scroll',
                    overflowX: 'hidden'}}
            >
                <div className="title">
                    {this.props.title}
                </div>
                {this.props.children}
            </div>    
        )
    }
}
