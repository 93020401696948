import Bagel from '@bageldb/bagel-db';

async function saveCompanyId(email: string) {
    let newVar = await getDB().collection('userConfig')
        .perPage(1000)
        .pageNumber(1)
        .query('email', '=', email)
        .get();
    let value = newVar.data[0].companyRef[0].itemRefID;
    localStorage.setItem("COMPANY_ID", value);
}

export const authenticateUserJSAPI =
    async (email: string, password: string): Promise<any> => {

        console.log(email)
        let db = getDB();

        let bagelUserID = db.users().getBagelUserID();
        let hasCompany = localStorage.getItem("COMPANY_ID")
        console.log(bagelUserID)
        console.log("company: " + hasCompany)
        if (!hasCompany) {
            let validate = db.users().validate(email, password);
            await saveCompanyId(email);
            console.log("Validate" + validate)
            return validate;
        }
        return null;
    };

export const getDB = () => {
    console.log("ENVIRONMENT is - " + process.env.REACT_APP_ENVIRONMENT)
    return new Bagel(process.env.REACT_APP_CLIENT_TOKEN);
}

export const logoutAPI = () => {
    return getDB().users().logout();
}

export const isLoginAPI = () => {
    return getDB().users()._bagelUserActive();
}

export const getUserIdIfExist = () => {
    return getDB().users().getBagelUserID();
}

export const getUserConfig =
    async (email: string): Promise<any> => {

        return getDB().collection('userConfig')
            .perPage(1000)
            .pageNumber(1)
            .projectOn('companyRef,fullName')
            .query('email', '=', email)
            .get();
    };
