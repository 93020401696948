import {Post} from '../interfaces';
import * as types from '../types';
import {getEngagementAPI} from "../../services/engagement.service";


export const approvePostsFetch = () => {
    return async (dispatch: any) => {
        try {
            const engagementResponse = await getEngagementAPI();

            let posts: Post = engagementResponse.data
                .filter((post: any) => {
                    return post.status === 'pending'
                })
                .map((post: any) => {
                    return {
                        id: post._id,
                        url: post.url,
                        embedHtml: post.embedHtml,
                        status: post.status
                    }
                })
            dispatch({
                type: types.POSTS_FETCH,
                approvePosts: posts,
            });
        } catch (error: any) {
            console.log(error)
        }
    }
}
