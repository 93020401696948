import React, {ChangeEvent, Fragment} from 'react';
import './ApprovePost.scss';
import * as interfaces from "../../../redux/interfaces";
import {getSrcFromStringIframe} from "../../shared_components/functions/htmlFunctions";
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons';

interface Props {
    approveLeadershipPost: (postId: string, leadershipId: string, expiration: any, notes: string) => void;
    rejectLeadershipPost: (postId: string) => void;
    approvePostsFetch: () => void;
    leadershipFetch: () => void;
    leaderships: interfaces.Leadership[];
    approvePosts: interfaces.Post[];
}

interface State {
    current: number;
    notes: any;
    date: any;
    categoryId: any
}

export class ApprovePost extends React.PureComponent <Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            current: 0,
            notes: '',
            date: '',
            categoryId: -1
        }
    }

    async componentDidMount() {
        await this.props.leadershipFetch();
        await this.props.approvePostsFetch();
    }

    incrementCurrent = () => {
        let current = this.state.current;
        let nnn = current + 1;
        if (nnn < this.props.approvePosts.length) {
            this.setState({current: nnn})
        }


    }

    decrementCurrent = () => {
        let current = this.state.current;
        let nnn = current - 1;
        if (nnn >= 0) {
            this.setState({current: nnn})
        }

    }

    handleUDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({date: event.target.value});
    }

    handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({notes: event.target.value});
    }
    approveFromButtonListener = async () => {
        let postId = this.currentPost().id;
        let categoryId = this.props.leaderships[this.state.categoryId].id;
        let date = this.state.date;
        let notes = this.state.notes;
        await this.props.approveLeadershipPost(postId, categoryId, date, notes)
        await this.props.approvePostsFetch();
    }

    rejectFromButtonListener = async () => {
        await this.props.rejectLeadershipPost(this.currentPost().id)
        await this.props.approvePostsFetch();

    }

    render() {
        return (
            <Fragment>
                <main
                    style={{minHeight: window.innerHeight}}
                    className={`col bg-faded py-3 flex-grow-1`}>
                    <div className="p-5 header1">
                        <div className="container">
                            <div className="row">
                                <div className="col-11 mx-auto">
                                    <h4 className="text-primary">Post for Approval
                                        <span className="float-right">
                                            {this.arrows()}
                                        </span>
                                    </h4>

                                </div>

                            </div>
                        </div>
                        {!!this.props.approvePosts.length && this.content()}
                        {!this.props.approvePosts.length &&
                        ApprovePost.noPostsToApprove()
                        }


                    </div>
                </main>


            </Fragment>

        )
    }

    private static noPostsToApprove() {
        return <Fragment>
            <div className="container">
                <div className="form-container m-5">
                    <div className="container">
                        <div className="form-wrapper p-4">
                            <div className="row d-flex align-items-center justify-content-center">
                                <div className="h-300 d-flex align-items-center">
                                    <h4 className="text-light-blue text-center p-5">No Posts to Approve</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }

    private content() {
        return <Fragment>
            <div className="container">
                <div className="form-container m-5">
                    <div className="container">
                        <div className="form-wrapper p-4">
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-md-5 ">
                                    {this.leftSlide()}
                                </div>
                                <div className="col-12 mt-5 mt-md-0 col-md-7">
                                    {this.rightSlide()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>;
    }

    private rightSlide() {

        const inputValidated = this.state.date.length > 0 && this.state.categoryId >= 0
        return <form className="">
            {this.chooseType()}

            <div className="form-group">
                <label>CHOOSE EXPIRATION DATE </label><br/>
                <input type="date" className="form-control form-control-lg" onChange={this.handleUDateChange}
                       value={this.state.date}/>
            </div>

            <div className="form-group">
                <label>NOTE</label><br/>
                <input type="text" className="form-control form-control-lg" onChange={this.handleNoteChange}
                       value={this.state.notes}/>
            </div>

            <div className="col d-flex justify-content-end pr-0">
                <button type="button" className="btn btn-light-blue mr-3 col-3"
                        onClick={this.rejectFromButtonListener}>REJECT
                </button>
                <button type="button" className="btn btn-primary col-3" disabled={!inputValidated}
                        onClick={this.approveFromButtonListener}>ACCEPT
                </button>

            </div>

        </form>;
    }

    onCategoryChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        this.setState({categoryId: event.target.value})
    }

    private chooseType() {
        return <div className="form-group">
            <label>CHOOSE TOPIC </label>
            <select className="form-control form-control-lg" onChange={this.onCategoryChanged}>
                {this.props.leaderships.map((leadership: interfaces.Leadership, index) => {
                    return (
                        <option value={index}>{leadership.title}</option>
                    )
                })}
            </select>
        </div>;
    }

    private leftSlide() {
        let post = this.currentPost();
        if (!post) {
            return <div/>
        }


        return <div className="left-side">
            <div className="text-label text-center text-left pb-2"> Preview</div>
            <div className="d-flex justify-content-center">
                <iframe src={getSrcFromStringIframe(post.embedHtml)} height="350" width="300" frameBorder="0"
                        title="Embedded post"/>
            </div>
        </div>;
    }

    private currentPost() {
        let current = this.state.current;
        return this.props.approvePosts[current];
    }

    private arrows() {
        let currentNumber = this.state.current + 1;
        if (this.props.approvePosts.length) {
            return (
                <div className={"row d-flex align-items-center"}>
                    <ChevronLeft onClick={this.decrementCurrent}/>
                    <p className="mb-0">
                        {currentNumber + "/" + this.props.approvePosts.length}
                    </p>
                    <ChevronRight onClick={this.incrementCurrent}/>
                </div>
            );
        }

    }
}
