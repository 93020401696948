import * as types from '../types';

export interface AuthState {
  isLogin:boolean;
  }
  
// INITIALIZE STATE
const initialState: AuthState = {
  isLogin:false
}


const authReducer = (state: AuthState = initialState, action: any) => {

    switch (action.type) {
      case types.AUTH_LOGIN :
        return {...state,isLogin:action.isLogin};
      default:
        return state;
    }
};

export default authReducer;
