import React, {Fragment} from "react";
import "./Company.scss";
import {Column} from "../../shared_components/Column/Column";
import {Card} from "../../shared_components/Card/Card";
import * as interfaces from "../../../redux/interfaces";
import {getHeightFromStringIframe, getSrcFromStringIframe} from "../../shared_components/functions/htmlFunctions";
import {TwitterTimelineEmbed,} from "react-twitter-embed";

// import { FacebookProvider, Page,Feed } from 'react-facebook';

interface Props {
    leadershipFetch: () => void;
    leaderships: interfaces.Leadership[];
    companyFetch: () => void;
    company: interfaces.Company;
}

interface State {
}

export class Company extends React.PureComponent<Props, State> {
    componentDidMount() {
        this.props.companyFetch();
    }

    render() {
        return (
            <Fragment>
                <main
                    className={`col bg-faded py-3 flex-grow-1`}
                >
                    <div className="container-fluid">
                        <div className="p-5 header1">
                            <div className="row">
                                <h4 className="text-primary col-7">COMPANY ENGAGEMENT</h4>
                            </div>
                            <div className="row mt-3">
                                {this.props.company && (
                                    <React.Fragment>
                                        <div className="col-12 col-lg-4 col-md-6">
                                            <Column
                                                title={`${this.props.company.name} Facebook`}
                                                height={`calc(${window.innerHeight}px - 100px)`}
                                                overflow='hidden'
                                            >
                                                <Card>
                                                    <iframe
                                                        src={getSrcFromStringIframe(
                                                            this.props.company.embedFacebook
                                                        )}
                                                        className="iframe"
                                                        height="1000"
                                                        width="100%"
                                                        data-height="1000"
                                                        data-width="500"
                                                        frameBorder="0"
                                                        title="Embedded post"
                                                    />
                                                    {/* <iframe src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F200AppsLtd&tabs=timeline&width=500&height=1000&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=159038298418842`}
                                                    className="iframe" height="1000" width="400" data-height="1000" data-width="500" frameBorder="0" title="Embedded post"></iframe> */}
                                                </Card>
                                            </Column>
                                        </div>

                                        <div className="col-12 col-lg-4 col-md-6 mt-5 mt-md-0">
                                            <Column
                                                title={`${this.props.company.name} Twitter`}
                                                height={`calc(${window.innerHeight}px - 100px)`}
                                                overflow='hidden'
                                            >
                                                <Card>
                                                    <TwitterTimelineEmbed
                                                        sourceType="profile"
                                                        screenName="200AppsLtd"
                                                        options={{height: 1000, width: 600}}
                                                    />
                                                </Card>
                                            </Column>
                                        </div>
                                        {this.props.leaderships
                                            .filter((leadership: interfaces.Leadership) => {
                                                return leadership.company
                                            })
                                            .map((leadership: interfaces.Leadership) => {
                                                let posts = leadership.posts;
                                                return (
                                                    <div className="col-12 mb-4 mb-md-0 col-lg-4 col-md-12"
                                                         key={leadership.id}>
                                                        <Column title={leadership.title} height={`calc(${window.innerHeight}px - 100px)`}>
                                                            {posts
                                                                .filter((post: interfaces.Post) => {
                                                                    return post.embedHtml && getSrcFromStringIframe(post.embedHtml);
                                                                })
                                                                .map((post: interfaces.Post) => {
                                                                    let srcFromStringIframe = getSrcFromStringIframe(post.embedHtml);
                                                                    return (
                                                                        <Card key={post.id}
                                                                              height={getHeightFromStringIframe(post.embedHtml) * 1.7}>
                                                                            <iframe src={srcFromStringIframe}
                                                                                    className="iframe"
                                                                                    height="100%" width="100%"
                                                                                    frameBorder="0"
                                                                                    title="Embedded post"/>
                                                                        </Card>
                                                                    )
                                                                })}
                                                        </Column>
                                                    </div>
                                                )
                                            })}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                </main>
            </Fragment>
        );
    }
}
