import {connect} from "react-redux";
import {Auth} from "./Auth";
import {bindActionCreators} from "redux";
import { loginUser } from "../../../redux/actions/Auth.action";

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({ 
        loginUser,
    }, 
    dispatch);

const mapStateToProps = (state: any) => {
    return {
        isLogin:state.authReducer.isLogin,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Auth)
