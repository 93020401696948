import {authenticateUserJSAPI, getUserConfig, isLoginAPI, logoutAPI} from '../../services/auth.service';
import * as types from '../types';
import {stringify} from "querystring";


export const loginUser =  (email:string,password:string)=>{

    return async (dispatch:any)=> {
      try {

        await authenticateUserJSAPI(email, password);
        // let userConfig = await getUserConfig(email);
        dispatch({
          type: types.AUTH_LOGIN,
          isLogin: true,
        });
      }catch(error:any){
        console.log(error)
      }
    }
  }

  export const getIsLogin = ()=>{
    return async (dispatch:any)=> {

      try{
        let hasCompany = localStorage.getItem("COMPANY_ID")

        const islogin = await isLoginAPI();
        dispatch ({
          type: types.AUTH_LOGIN,
          isLogin: hasCompany,
        });

      }catch(error:any){
        dispatch ({
          type: types.AUTH_LOGIN,
          isLogin: false,
        });
      }
    }
  }

  export const logout = ()=>{
    return async (dispatch:any)=> {
      try {
        dispatch ({
          type: types.AUTH_LOGIN,
          isLogin: false,
        });
        await localStorage.clear()
        await logoutAPI();
      }catch(error:any){
        console.log(error);
        
      }
    }
  }