import * as leadershipService from '../../services/leadership.service';
import {getLeadershipAPI} from '../../services/leadership.service';
import {Leadership, Post} from '../interfaces';
import * as types from '../types';
import {getEngagementAPI} from "../../services/engagement.service";


export const leadershipFetch = ()=>{
    return async (dispatch:any)=> {
      try{
          const leadershipResponse = await getLeadershipAPI();
          const engagementResponse = await getEngagementAPI();
        let leaderships:Leadership = leadershipResponse.data.map((leadership:any)=>{
            let leadershipPosts = engagementResponse.data.filter((c: any) => {
                return leadership._id === c.category[0]?.itemRefID
            });
            return {
                id: leadership._id,
                title: leadership.title,
                company: leadership.company,
                posts: leadershipPosts.map((post: any) => {
                    return {
                        id: post._id,
                        url: post.url,
                        embedHtml: post.embedHtml,
                        expiration: post.expiration,
                    }
                })
            }
        });

        dispatch ({
            type: types.LEADERSHIP_FETCH,
            leaderships:leaderships,
          });
      }catch(error:any){
        console.log(error)
      }
    }
  }

/**/
  export const postLeadershipPost = (
      leadershipId: string,
      postUrl: string,
      embedHtml: string,
      expiration: any,
      notes: string,
      newTopic: string
  )=>{
    return async (dispatch:any)=> {
        try {

            let topicId
            if (newTopic !== '') {
                const newTopicResponse = await leadershipService.createTopic(newTopic)
                topicId = newTopicResponse.data.id
            } else {
                topicId = leadershipId
            }
            const response = await leadershipService.postPostByLeadershipIdApi(topicId, postUrl, embedHtml, expiration, notes);
            let post: Post = {
                id: response.data.id,
                embedHtml: embedHtml,
                url: postUrl,
                status: "approved"
            }
            await dispatch({
                type: types.NEW_POST,
                leadershipId: topicId,
                post: post,
            });
        } catch (error: any) {
            console.log(error)
        }
    }
  }

export const rejectLeadershipPost = (
    postId: string
) => {
    return async () => {
        try {
            await leadershipService.rejectPostById(postId);
        } catch (error: any) {
            console.log(error)
        }
    }
}


export const approveLeadershipPost = (postId: string, leadershipId: string, expiration: any, notes: string) => {
    return async () => {
        try {
            await leadershipService.acceptPostById(postId, leadershipId, expiration, notes);

        } catch (error: any) {
            console.log(error)
        }
    }
}