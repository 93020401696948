import {connect} from "react-redux";
import {ApprovePost} from "./ApprovePost";
import {bindActionCreators} from "redux";
import {approveLeadershipPost, leadershipFetch, rejectLeadershipPost} from "../../../redux/actions/Leadership.action";
import {approvePostsFetch} from "../../../redux/actions/ApprovePost.Action";

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({
            approveLeadershipPost,
            rejectLeadershipPost,
            approvePostsFetch,
            leadershipFetch
        },
        dispatch);

const mapStateToProps = (state: any) => {
    return {
        approvePosts: state.approvePostReducer.approvePosts,
        leaderships:state.leadershipReducer.leaderships
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovePost)
