import { createStore, applyMiddleware ,combineReducers} from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/Auth.reducer";
import companyReducer from "./reducers/Company.reducer";
import leadershipReducer from "./reducers/Leadership.reducer";
import approvePostReducer from "./reducers/ApprovePost.reducer";


const AppReducer = combineReducers({
    authReducer,
    companyReducer,
    leadershipReducer,
    approvePostReducer
});


export const appStore = 
    createStore(AppReducer, applyMiddleware(thunk));