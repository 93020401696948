import {db} from './general.service';

export async function createTopic(newTopic: string) {
    const companyId = localStorage.getItem("COMPANY_ID");
    let database = db()
    return database.collection('leadership')
        .post({
            title: newTopic,
            companyRef: [{itemRefID: companyId}]
        })
}


export const getLeadershipAPI = (): Promise<any> => {
    let database: any = db();
    return database.collection('leadership')
        .perPage(1000)
        .pageNumber(1)
        .projectOn('posts,title,company').get();
};

export const getPostsByIdAPI = (topicId: string): Promise<any> => {
    let database: any = db();
    return database.collection('leadership')
        .item(topicId)
        .collection('posts')
        .perPage(1000)
        .pageNumber(1)
        .projectOn('posts')
        .get();
};


export const postPostByLeadershipIdApi = (leadershipId: string, postUrl: string, embedUrl: string, expiration: any, notes: string) => {
    const companyId = localStorage.getItem("COMPANY_ID");
    let database: any = db();
    return database.collection('engagement')
        .post({
            embedHtml: embedUrl,
            url: postUrl,
            category: [{itemRefID: leadershipId}],
            company: [{itemRefID: companyId}],
            status: "Approved",
            expiration: new Date(Date.parse(expiration)),
            notes: notes
        })

}

export async function acceptPostById(postId: string, leadershipId: string, expiration: any, notes: string) {
    let database: any = db();
    let post = {
        category: [{itemRefID: leadershipId}],
        expiration: new Date(Date.parse(expiration)),
        notes: notes,
        status: "Approved"
    };
    return database.collection('engagement').item(postId)
        .put(post);

}

export const rejectPostById = (postId: string) => {
    let database: any = db();
    return database.collection('engagement').item(postId).put({status: "Rejected"});

}

