import React, {Fragment} from "react";
import {Pages} from "../functions/Navigation";
import "./Nav.scss";

import navImage from "../../../assets/images/logo-nav.png";
import leadership from "../../../assets/images/leadership.svg";
import addpost from "../../../assets/images/addpost.svg";
import approval from "../../../assets/images/approval.svg";
import company from "../../../assets/images/company.svg";

interface Props {
    pathname: any;
    onClick: (path: string) => void;
    onLogout: () => void;
}

interface State {
}

export class Nav extends React.PureComponent<Props, State> {
    render() {
        return (
            <Fragment>
                <aside className="col-12 col-md-2 p-0 bg-primary">
                    {this.navigationSection()}
                    {this.logout()}
                </aside>
            </Fragment>
        );
    }

    private navigationSection() {
        return <nav className="navbar navbar-dark bg-primary px-2 px-md-0 ">
            <ul className="navbar-nav w-100 pr-0">
                {this.header()}
                {this.getNavItem(leadership, <>THOUGHT <br/>LEADERSHIP <br/>ENGAGEMENT</>, Pages.leadership)}
                {this.getNavItem(addpost, <>ADD POSTS</>, Pages.addPost)}
                {this.getNavItem(company, <>COMPANY <br/>ENGAGEMENT</>, Pages.company)}
                {this.getNavItem(approval, <>POSTS FOR <br/> APPROVAL</>, Pages.approve)}
            </ul>
        </nav>;
    }

    private logout() {
        return <ul className="navbar-nav pr-0">
            <li className={`nav-item  py-2`} onClick={this.props.onLogout}>
                <a className="nav-link">
                    <div className="row mx-auto d-flex justify-content-center">
                        <span className="px-5 mt-5 py-3 text-white logout-border">Logout</span>
                    </div>
                </a>
            </li>
        </ul>;
    }

    private header() {
        return <li className="nav-item">
            <a className="nav-link px-0 py-4">
                <span className="font-weight-bold d-flex justify-content-center">
                    <img src={navImage} width="200"/>
                </span>
            </a>
        </li>;
    }

    private getNavItem(icon: any = leadership, text: JSX.Element, page: Pages) {
        return <li className={`nav-item py-2 ${this.props.pathname === page ? "active" : ""}`}
                   onClick={() => this.props.onClick(page)}>
            {<a className="nav-link">
                <div className="row  mx-auto d-flex justify-content-start">
                    <span className="d-flex align-items-center ml-5">
                        <img src={icon} width="30" className="icon"/>
                    </span>
                    <span className="pl-4">{text} </span>
                </div>
            </a>}
        </li>;
    }
}