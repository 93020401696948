import React, {Component, Fragment, Suspense} from "react";
import "./App.scss";
import Auth from "./components/pages/Auth/AuthContainer";

import {Nav} from "./components/shared_components/Nav/Nav";
import {Redirect, Route, Switch} from "react-router-dom";
import {
    currentRoute,
    Pages,
} from "./components/shared_components/functions/Navigation";
import Company from "./components/pages/Company/CompanyContainer";
import AddPost from "./components/pages/AddPost/AddPostContainer";
import Leadership from "./components/pages/Leadership/LeadershipContainer";
import ApprovePost from "./components/pages/ApprovePost/ApprovePostContainer";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getIsLogin, logout} from "./redux/actions/Auth.action";

interface Props {
    location?: any;
    history: any;
    isLogin: boolean;
    getIsLogin: () => void;
    logout: () => void;
}

interface State {
}

class App extends Component<Props, State> {
    componentDidMount() {
        this.props.getIsLogin();
    }

    navigateTo = (pathname: string) => {
        this.props.history.push(pathname);
    };

    render() {
        const isLogin = this.props.isLogin;
        console.log("isLogin + " + isLogin)
        return (
            <Fragment>

                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        {isLogin && (

                            <div className="container-fluid px-0">
                                {/*  min-vh-100 flex-column flex-md-row */}
                                <div className={`row mx-0`}>
                                    <Nav
                                        pathname={currentRoute(this.props.location)}
                                        onClick={this.navigateTo}
                                        onLogout={this.props.logout}
                                    />

                                    <Route
                                        exact
                                        path={Pages.leadership}
                                        component={Leadership}
                                    />


                                    <Route exact path={Pages.company} component={Company}/>


                                    <Route exact path={Pages.addPost} component={AddPost}/>


                                    <Route exact path={Pages.approve} component={ApprovePost}/>


                                    <Redirect from={Pages.login} to={Pages.home}/>


                                </div>
                            </div>
                        )}

                        {!isLogin && (
                            <Fragment>
                                <div className="container-fluid px-0">
                                    <Route exact path={Pages.login} component={Auth}/>

                                    <Redirect from="/" to={Pages.login}/>
                                </div>

                            </Fragment>

                        )}


                    </Switch>
                </Suspense>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            getIsLogin,
            logout,
        },
        dispatch
    );

const mapStateToProps = (state: any) => {
    return {
        isLogin: state.authReducer.isLogin,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default ;
