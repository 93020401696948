import "./LoginForm.scss";
import logo from "../../../../assets/images/logo.png";
import * as React from "react";
import { useState } from "react";
import { ChangeEvent } from "react";

interface Props {
    onSubmit:(credentials:any)=>void;
}


export const LoginForm = React.memo((props: Props) => {

        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');


        const handleEmail = (event: ChangeEvent<HTMLInputElement>)=>{
            setEmail(event.target.value)
        }

        const handlePassword = (event: ChangeEvent<HTMLInputElement>)=>{
            setPassword(event.target.value)
        }

        const OnSubmitForm = ()=>{
            if(email && password){
                props.onSubmit({email,password})
            }
        }
        
        return <div className="py-4 login-form">
            <img src={logo}  className="logo" alt=""/>

            <form className="col-10 py-4 mt-2 mx-auto">
                {/* <h5 className="text-primary">Login</h5> */}
            <div className="form-group">
              <label>Email Address</label>
              <input type="email" className="form-control" onChange={handleEmail}  value={email} />
              </div>

            <div className="form-group">
              <label>Password</label>
              <input type="password" className="form-control" onChange={handlePassword} value={password} />
            </div>

            <button onClick={OnSubmitForm} className="col btn btn-primary">Submit</button>
        </form>

        </div>
        
    }
);
