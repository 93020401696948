import {db} from './general.service';

export const  getEngagementAPI = () : Promise<any> => {
    let database:any = db();
    return database.collection('engagement').perPage(1000).pageNumber(1)
        // .projectOn('url,embedHtml,category,status,approval, expiration')
        .get();
};


export const  getPostsByIdAPI = (topicId:string) : Promise<any> => {
    let database:any = db();
    return database.collection('engagement')
    .item(topicId)
    .collection('posts').perPage(1000).pageNumber(1).projectOn('posts').get();
};

export const postPostbyEngagementIdApi = (engagementId:string,postUrl:string,embedUrl:string)=>{
    let database:any = db();
    return database.collection('engagement')
    .item(engagementId)
    .collection('posts')
    .post({
        embedHtml:embedUrl,
        url:postUrl
    })

}