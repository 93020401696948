import { Post} from '../interfaces';
import * as types from '../types';

export interface ApprovePostState {
  approvePosts:Post[]
  }
  
// INITIALIZE STATE
const initialState: ApprovePostState = {
  approvePosts:[]
}


const approvePostReducer = (state: ApprovePostState = initialState, action: any) => {

    switch (action.type) {
      case types.POSTS_FETCH :
        return {...state,approvePosts:action.approvePosts};
      default:
        return state;
    }
};

export default approvePostReducer;
