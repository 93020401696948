import {Post} from "../../../redux/interfaces";

export const getSrcFromStringIframe = (stringIframe: string) => {
    try {
        let htmlObject: any = document.createElement('div');
        htmlObject.innerHTML = stringIframe;
        return htmlObject.getElementsByTagName("iframe")[0].src;
    } catch (e) {
        try {
            let htmlObject: any = document.createElement('div');
            htmlObject.innerHTML = stringIframe;
            return htmlObject.getElementsByTagName("blockquote")[0].src;
        } catch (e) {
            return null
        }
    }
}

export const getHeightStringFromStringIframe = (stringIframe: string) => {
    return getHeightFromStringIframe(stringIframe) + "px"

}


export const getHeightFromStringIframe = (stringIframe: string) => {
    try {
        let htmlObject: any = document.createElement('div');
        htmlObject.innerHTML = stringIframe;
        return htmlObject.getElementsByTagName("iframe")[0].height * 1.0
    } catch (e) {
        return 1000
    }

}

export const getWidthFromStringIframe = (stringIframe: string) => {
    let htmlObject: any = document.createElement('div');
    htmlObject.innerHTML = stringIframe;
    return htmlObject.getElementsByTagName("iframe")[0].width

}

export const getCombinedHeight = (posts: Post[]) => {
    return posts.reduce((total: number, obj: Post) => {
        return total + getHeightFromStringIframe(obj.embedHtml)
    }, 0)

}


export const getMaxWidth = (posts: Post[]) => {

    return posts.reduce((total: number, obj: Post) => {
        let width = getWidthFromStringIframe(obj.embedHtml);
        return total > width ? total : width
    }, 0)

}