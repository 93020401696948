export enum Pages {
    home = '/',
    leadership = '/',
    company = '/company',
    addPost = '/addpost',
    approve = '/approve',
    login = '/login'
}


export const Navigation = () => ({
    leadership: {
        visit: Pages.leadership
    },
    company: {
        visit: Pages.company
    },
    addpost: {
        visit: Pages.addPost
    },
    approve: {
        visit: Pages.approve
    },
    login: {
        visit: Pages.login
    },
})


export const currentRoute = (location: any) => location.pathname;