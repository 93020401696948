import { Company } from '../interfaces';
import * as types from '../types';

export interface CompanyState {
    company:Company|undefined
  }
  
// INITIALIZE STATE
const initialState: CompanyState = {
    company:undefined
}


const companyReducer = (state: CompanyState = initialState, action: any) => {

    switch (action.type) {
      case types.COMPANY_FETCH :
       return {...state,company:action.company};
      default:
        return state;
    }
};

export default companyReducer;
