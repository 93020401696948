import React from 'react';
import "./Card.scss";

interface Props {
    children?: any
    height?: number
    style?:any
}

interface State {

}

export class Card extends React.PureComponent <Props, State> {


    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div  className="col card-container p-0"   style={{   minHeight: this.props.height + "px" , ...this.props.style }}>
                {this.props.children}
            </div>
        )
    }
}
