import React from 'react';
import './Auth.scss';
import video from '../../../assets/login.mp4';
import { LoginForm } from './LoginForm/LoginForm';

interface Props {
    loginUser:(email:string,password:string)=>void;
}

interface State {

}
export class Auth extends React.PureComponent <Props, State> {


    componentDidMount(){

    }

    onSubmit = (credentials:any)=>{
        this.props.loginUser(credentials.email,credentials.password);
        // this.props.loginUser("sari@200apps.co","123456");
        
    }

    render(){
        return (
        <React.Fragment>
           <div className="container-login">
           
             <video autoPlay muted loop id="bg-video"  controls={false}>
             <source src={video} type="video/mp4"/>
            </video>
            <div className="row pt-5">
                <div className="col-10 mx-auto form-container">
                <LoginForm onSubmit={this.onSubmit}/>
                </div>
            </div>
        </div>
        </React.Fragment>)
    }
}
