import {connect} from "react-redux";
import {AddPost} from "./AddPost";
import {bindActionCreators} from "redux";
import {postLeadershipPost,leadershipFetch } from "../../../redux/actions/Leadership.action";

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators({ 
        postLeadershipPost,leadershipFetch
    }, 
    dispatch);

const mapStateToProps = (state: any) => {
    return {
        leaderships:state.leadershipReducer.leaderships
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPost)
